<template>
  <div v-swiper:ProjectSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 news-pagination fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'ProjectSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 14,
        noSwiping: false,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          clickable: true,
          el: '.news-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          600: {
            slidesPerView: 2,
            spaceBetween: 14,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 16,
          }
        }
      }
    }
  }
}
</script>
