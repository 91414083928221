import Vue from 'vue'
window.Vue = Vue
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import $ from "jquery";
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppFixedHeader from './components/AppFixedHeader'
import ScrollSlider from './components/ScrollSlider'
import NewsSlider from './components/NewsSlider'
import ProjectSlider from './components/ProjectSlider'
import ShowForm from './components/ShowForm'
import HeroSlider from './components/HeroSlider'
import NewsLetter from './components/NewsLetter'
import AppNavBar from './components/AppNavBar'
import ContactForm from './components/ContactForm'
import QuoteForm from './components/QuoteForm'
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('ShowForm', ShowForm)
Vue.component('ScrollSlider', ScrollSlider)
Vue.component('NewsSlider', NewsSlider)
Vue.component('NewsLetter', NewsLetter)
Vue.component('ProjectSlider', ProjectSlider)
Vue.component('HeroSlider', HeroSlider)
Vue.component('ContactForm', ContactForm)
Vue.component('QuoteForm', QuoteForm)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
$(document).ready(function(){
  $(".card").slice(0,12).show();
  $("#load-more").click(function(e){
    e.preventDefault();
    $(".card:hidden").slice(0,12).fadeIn("slow");
    // or make ajax call and append

    if($(".card:hidden").length == 0){
      $("#load-more").fadeOut("slow");
    }
    // $('html, body').animate({
    //   scrollTop: $("#myteam").offset().top,
    // }, 2000);
  });
  $('.form-toggler').on('click', function() {
    $('.subscriptio-form').toggleClass('visible-form');
  });
  $('.dinner-toggler').on('click', function() {
    $('.dinner-form').toggleClass('visible-form');
  });
  $('.toggle-me').on('click', function() {
    if ($('.menu-item').hasClass('visible')) {
      $('.menu-item').removeClass('visible');
      $('.menu-item').removeClass('visibles');
      $('.comp').removeClass('visibles');
      $(".scrollers").delay(300).animate({ scrollTop: 0 }, "fast");
    }
    else
    {
      document.querySelectorAll("#menu-main-menu li").forEach((li, i) => {
        setTimeout(() => {
          li.classList.add('visible');
        }, 50 * i);
      });
      setTimeout(function(){
      $('.comp').addClass('visibles');
      },400);
      setTimeout(function(){
        document.querySelectorAll("#menu-main-menus li").forEach((li, i) => {
          setTimeout(() => {
            li.classList.add('visibles');
          }, 50 * i);
        });
      },400);

    }
  });
  $('#nav-icon4').click(function(){
    $(this).toggleClass('open');
  });
  $('.accordion-list > li > .answer').hide();

  $('.accordion-list > li > .clickavle').click(function() {
    if ($(this).parent().hasClass("active")) {
      $(this).parent().removeClass("active").find(".answer").slideUp();
    } else {
      $(".accordion-list > li.active .answer").slideUp();
      $(".accordion-list > li.active").removeClass("active");
      $(this).parent().addClass("active").find(".answer").slideDown();
    }
    return false;
  });
  $(".toggler").click(function(){
    $(".tog-me").toggleClass("mt-1000px");
  });
  $(".togglercard").click(function(){
    $(this).toggleClass("moved-card");
  });
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 0.4 * $(window).height()) {
      $("#nav").addClass('fadeout');
    } else {
      $("#nav").removeClass('fadeout');
    }
  });
});




