<template>
 <div>
   <div id="nav" class="relative z-30">
     <div class="w-screen nav-bg py-8 overflow-hidden border-t-11 border-red-500 relative z-50 px-4 sm:px-8">
       <div class="container justify-between relative flex items-center ">
         <div class="relative flex sm:gap-x-12 items-center justify-start mb-0">
           <slot name="brand" />
           <slot name="fl" />
         </div>

         <div class="-mt-6">
           <div class="hidden lg:flex gap-x-6 items-end mb-6 justify-end">
             <slot name="cart"/>
           </div>
           <div class="flex justify-end gap-x-6 float-right items-center">
             <div class="relative mb-0 hidden xl:flex items-center gap-x-6">
               <a href="/sponsorship/#sponsor-brick-scroll" class="bricks-spon">Sponsor a brick</a>
               <a href="/donate" class="text-base text-white font-semibold bg-secondary px-4 py-2 rounded-lg hover:bg-primary transition-all ease-in-out duration-300">DONATE</a>
             </div>
             <div class="h-6">
               <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;showmenu();" class="text-center main-men text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
                 <div id="nav-icon4" :class="{ open: isActive }">
                   <span></span>
                   <span></span>
                   <span></span>
                   <p>MENU</p>
                 </div>
               </button>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div :class="{ desktopmenu: isActive }"  class="relative overflow-hidden px-8 main-show w-screen block py-12 lg:py-32 menu-slide-bg">
     <img src="/app/themes/wordpress/assets/images/mobile-menu-shape.svg" class="absolute bottom-0 lg:w-w-96 -left-8" alt="Friends For Life Logo" style="height:auto!important; max-width: 340px!important;">
     <div class="relative  container z-10">
       <slot name="mainmenu" />
      <div class="space-y-6 sm:space-y-0 sm:flex justify-between items-center">
        <div class="flex lg:hidden gap-x-6">
          <div class="lg:hidden"><a href="/sponsorship/#sponsor-brick-scroll" class="text-18 mt-6 donator font-semibold border border-secondary px-4 py-2 rounded-lg inline-block">Sponsor a brick</a></div>
          <div class="lg:hidden"><a href="/donate/" class="text-18 font-bold mt-6 inline-block font-semibold mx-auto text-white bg-secondary px-4 py-2 rounded-lg">DONATE</a></div>
        </div>
        <div class="flex lg:hidden gap-x-6">
          <slot name="cart"/>
        </div>
      </div>
     </div>
   </div>
 </div>
 </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addom () {
      document.querySelector('.desktopmenu').classList.toggle('offscreen')
    },
    addomt () {
      document.querySelector('.moverin').classList.toggle('onscreen')
    },
    showmenu () {
      document.querySelector('.content-pos').classList.toggle('showmenu')
    },
    removeo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  left: -101vw!important;
}
.invisi{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-left: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-left: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-left: 105vw;
  }
}
</style>
