<template>
  <div>
    <button type="button" @click="isShowed = !isShowed">Subscribe</button>
    <div v-if="isShowed">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isShowed: false,
    }
  },
}
</script>