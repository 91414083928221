<template>
  <div v-swiper:NewsSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="relative text-center top-0 lg:hidden news-pagination fibre-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'NewsSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1.4,
        loop: true,
        spaceBetween: 14,
        noSwiping: false,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          clickable: true,
          el: '.news-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          1023: {
            slidesPerView: 3,
            spaceBetween: 14,
          },
      }
      }
    }
  }
}
</script>
