<template>
  <validation-observer ref="form" v-slot="{ passes, invalid }">
    <div v-if="success" class="mb-8 text-primary border-white bg-transparent" role="alert">
      <div class="flex">
        <div>
          <p class="font-black text-32 uppercase mb-4">Thank You!</p>
          <p class="text-sm mb-6">{{ success }}</p>
          <a href="/" class="submit-btn transition-all ease-in-out duration-300 font-black">Home Page</a>
        </div>
      </div>
    </div>
    <div v-if="error" class="mb-8 border-2 text-white border-white bg-transparent p-4" role="alert">
      <div class="flex">
        <div class="py-1"><svg class="fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
        <div>
          <p class="font-black font-axia">Oops!</p>
          <p class="text-sm">{{ error }}</p>
        </div>
      </div>
    </div>
    <form  name="contact-form" :class="{ hidden: success }" class="grid gap-x-6 gap-y-6 text-left md:grid-cols-4" @submit.stop>
      <validation-provider v-slot="{ errors }" name="Firstname" rules="required" class="md:col-span-2">
        <div>

          <input
            id="form-firstname"
            placeholder="First Name*"
            v-model="form.firstname"
            :class="{ error: errors[0] }"
            type="text"
            class="input"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Company" class="md:col-span-2">
        <div>

          <input
            id="form-company"
            placeholder="Last Name*"
            v-model="form.company"
            :class="{ error: errors[0] }"
            type="text"
            class="input"
          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Email" rules="required|email" class="md:col-span-2">
        <div>

          <input
            id="form-email"
            placeholder="Email*"
            v-model="form.email"
            :class="{ error: errors[0] }"
            type="text"
            class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Mobile" class="md:col-span-2">
        <div>
          <input
              placeholder="Phone Number"
              id="form-mobile"
              v-model="form.mobile"
              :class="{ error: errors[0] }"
              type="text"
              class="input"

          />
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Message" class="md:col-span-4">
        <div>
          <textarea
            placeholder="Message"
            id="form-message"
            v-model="form.message"
            :class="{ error: errors[0] }"
            class="text-area"
            rows="4"
          ></textarea>
          <span v-show="errors" class="block mt-1 text-sm text-red-500">{{ errors[0] }}</span>
        </div>
      </validation-provider>
      <div class="md:col-span-4 text-right">
        <button type="button" :disabled="invalid" class="bg-secondary font-semibold text-white py-2 px-12 ml-auto rounded" @click="passes(SendMessage)">
          SUBMIT
        </button>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false,
      form: {}
    }
  },
  methods: {
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.loading = true
      this.$axios.post('/wp-json/icon/v1/contact', this.form)
        .then((response) => {
          this.form = {} // Clear input fields.
          this.$nextTick(() => this.$refs.form.reset())
          this.success = response.data.message
          this.error = false
          /*
          this.$notify({
            type: 'primary',
            showMethod: 'fade in right',
            message: response.data.message
          })*/
        })
        .catch((err) => {
          this.success = false
          this.error = err.response.data.message
          /*
          this.$notify({
            message: err.response.data.message
          })
          */
        })
        .then(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
  input:active{
    border:none!important;
  }
  input:focus-visible{
    outline-width: 0px;
  }
  textarea:focus-visible{
    outline-width: 0px;
  }
</style>
